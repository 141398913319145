<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ titleModal }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <b-overlay
          :show="processingAjax"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            class="b-sidebar-scrollarea-form"
            :settings="configSettingsScroll"
          >
            <b-container
              class="pt-2 pb-0 pl-2 pr-2"
              fluid
            >
              <b-row v-if="acceptType !== 0">
                <b-col md="12 mb-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    block
                    @click="$refs.refDocsProposal.$el.click()"
                  >
                    {{ $t('Carregar ficha de proposta digitalizada') }}
                  </b-button>
                  <b-form-file
                    ref="refDocsProposal"
                    accept="*"
                    :hidden="true"
                    plain
                    multiple
                    @input="uploadDocRender"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12 mb-1">
                  <p>{{ msgAccept }}</p>
                </b-col>
              </b-row>

              <div
                v-for="(row, index) in aFilesProposal"
                :key="`docs-${index}`"
                class="mt-1"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Documento')"
                    >
                      <div class="text-truncate txt-label text-primary text-break-word">
                        {{ row.name }}
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Legenda')"
                    >
                      <b-form-input
                        v-model="row.legend"
                        type="text"
                        autocomplete="off"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon mb-1"
                      @click="eliminarDoc(index)"
                    >
                      <span class="align-middle">{{ $t('Eliminar') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>

            </b-container>

          </vue-perfect-scrollbar>
          <div
            class="d-flex p-1 justify-content-center"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click.stop.prevent="hide"
            >
              {{ $t('Fechar') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1"
              @click="confirmProposal"
            >
              {{ titleButton }}
            </b-button>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BRow, BCol, VBTooltip, BButton, BOverlay, BFormFile, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { showModalDeleteMessage } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  components: {
    BSidebar,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    VuePerfectScrollbar,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    acceptType: {
      type: Number,
      required: true,
      default: 0,
    },
    checkProposalsState: {
      type: Function,
      required: true,
    },
    uploadDocs: {
      type: Function,
      required: true,
    },
    submitAcceptProposal: {
      type: Function,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      processingAjax: true,
      titleModal: '',
      titleButton: '',
      msgAccept: '',
      compraOnline: '',
      doc: '',
      comissao: '',
      aFilesUpload: {},
      aFilesProposal: [],
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['proposalDetail']),
  },
  async created() {
    await this.$store.dispatch('crm_proposals/checkProposalsState', { sw131s20: 'check', cleanAction: 0 }).then(res => {
      this.compraOnline = res.compraOnline
      this.doc = res.doc
      this.compcomissaoraOnline = res.comissao

      if (this.acceptType === 1) {
        this.titleModal = this.$t('O proprietário aceitou')
        if (res.compraOnline !== 0) {
          this.titleButton = this.$t('Aceitar')
          this.msgAccept = this.$t("Ao confirmar, esta proposta vai ficar no estado 'Pendente de pagamento' até o cliente proceder com o pagamento da caução.")
        } else if (res.doc === 1 && res.comissao === 0) {
          this.titleButton = this.$t('Aceitar e reservar')
          this.msgAccept = this.$t("Ao confirmar, o imóvel passará para o estado 'reservado' na aplicação e no website e os {cargo} serão notificados.", { cargo: `${this.proposalDetail.cargos.HMs}` })
        } else if (res.comissao === 1) {
          this.titleButton = this.$t('Submeter para aprovação')
          this.msgAccept = this.$t('A reserva da angariação depende da aprovação dos {cargo} devido ao facto das comissões envolvidas terem sido alteradas.', { cargo: `${this.proposalDetail.cargos.HMs}` })
        } else {
          this.titleButton = this.$t('Submeter para aprovação')
          this.msgAccept = this.$t('Não pode ser feita a reserva por não existir documentação suficiente na proposta. O processo passará para a aprovação dos {cargo}.', { cargo: `${this.proposalDetail.cargos.HMs}` })
        }
      } else if (this.acceptType === 2) {
        this.titleModal = this.$t('O comprador aceitou')
        if (res.proposals.length > 0) {
          this.titleButton = this.$t('Submeter para aprovação')
          this.msgAccept = this.$t('O consultor angariador tem em negociação, mais propostas para este imóvel. Aguarde a aprovação do seu lado e não assuma como reservado.')
        } else if (res.compraOnline === 1) {
          this.titleButton = this.$t('Aceitar')
          this.msgAccept = this.$t("Ao confirmar, esta proposta vai ficar no estado 'Pendente de pagamento' até o cliente proceder com o pagamento da caução.")
        } else if (res.doc === 1) {
          this.titleButton = this.$t('Aceitar e reservar')
          this.msgAccept = this.$t("Ao confirmar, o imóvel passará para o estado 'reservado' na aplicação e no website, e os seus {cargo} serão notificados.", { cargo: `${this.proposalDetail.cargos.HMs}` })
        } else {
          this.titleButton = this.$t('Submeter para aprovação')
          this.msgAccept = this.$t('Não pode ser feita a reserva por não existir documentação suficiente na proposta. O processo passará para a aprovação dos {cargo}.', { cargo: `${this.proposalDetail.cargos.HMs}` })
        }
      } else {
        this.titleButton = this.$t('Aceitar e reservar')
        this.msgAccept = this.$t("Ao confirmar, o imóvel passará para o estado 'reservado' na aplicação e no website.")
      }
    }).catch(error => {
      this.showMsgErrorRequest(error)
    })

    this.processingAjax = false
  },
  methods: {
    closeModal(val) {
      this.$parent.showFormAcceptProcess = val
    },
    async uploadDocRender(aFiles) {
      if (aFiles.length > 0) {
        this.aFilesUpload = aFiles
        for (let index = 0; index < aFiles.length; index += 1) {
          this.aFilesProposal.push({ name: aFiles[index].name, legend: '' })
        }

        if (aFiles.length === 1) {
          this.showMsgSuccessRequest({ message: this.$t('Ficheiro carregado com sucesso') })
        } else {
          this.showMsgSuccessRequest({ message: this.$t('Ficheiros carregados com sucesso') })
        }
      }
    },
    async eliminarDoc(index) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar este documento?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.aFilesProposal.splice(index, 1)
        }
      })
    },
    confirmProposal() {
      if (this.acceptType === 1) {
        this.$parent.countProposal = 0
        if (Number(this.compraOnline) === 1) {
          this.checkProposalsState(1)
        } else if (Number(this.proposalDetail.proposal.sw130s12) === 17 && Object.keys(this.aFilesUpload).length === 0) {
          this.showMsgErrorRequest(new Error(this.$t('É obrigatório submeter documentos!')))
        } else if (Number(this.doc) === 1 && Number(this.comissao) === 0) {
          if (Object.keys(this.aFilesUpload).length > 0) {
            this.checkProposalsState(1)
          } else {
            this.$swal({
              title: '',
              html: this.$t('Ao submeter sem anexar um documento da proposta digitalizada, a aprovação passa a depender dos {cargo}. Deseja continuar?', { cargo: `${this.proposalDetail.cargos.HMs}` }),
              icon: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('Continuar sem documento'),
              cancelButtonText: this.$t('Voltar'),
              customClass: {
                confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
                cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
              },
              buttonsStyling: false,
            }).then(async result => {
              if (result.value) {
                this.checkProposalsState(1)
              }
            })
          }
        } else {
          this.checkProposalsState(1)
        }
      } else if (this.acceptType === 2) {
        if (Number(this.compraOnline) === 1) {
          if (Object.keys(this.aFilesUpload).length > 0) {
            this.uploadDocs()
          } else {
            this.submitAcceptProposal()
          }
        } else if (Object.keys(this.aFilesUpload).length > 0) {
          this.uploadDocs()
        } else {
          this.submitAcceptProposal()
        }
      } else if (this.acceptType === 0) {
        this.submitAcceptProposal()
      }
    },
    resetFieldUpload() {
      this.resumeErrorUpload = []
      this.$refs.refDocsProposal.reset()
    },
    resetData() {
      this.titleModal = ''
      this.titleButton = ''
      this.msgAccept = ''
      this.compraOnline = ''
      this.doc = ''
      this.comissao = ''
      this.aFilesUpload = {}
      this.aFilesProposal = []
    },
  },
  setup() {
    const refDocsProposal = ref(null)

    return {
      refDocsProposal,
      configSettingsScroll,
    }
  },
}
</script>
